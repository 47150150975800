import { BlockMath } from 'react-katex';
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import gsap from "gsap";
import 'katex/dist/katex.min.css';

import IG4 from "../img/IG-step4.png"
import { FadeInOverload } from './AnimationComponents';

gsap.registerPlugin(ScrollTrigger);

export const ImmunitaGreggeMobile = () => {
    const linkStyle={
            color: "white", 
            textDecoration: "underline",
            textDecorationColor: "#f5ba00"
      }
    return (

            <section id="ImmunitaGreggeMobile" className="section show-x-small" >
                <div >
                    <aside className="sidebar-portrait">
                        <div className="first-half show-x-small">
                            <FadeInOverload offset={1550} JSX={
                                <h2 className="font-grouch" style={{ fontSize: "24px" }}>
                                    Immunità di gregge: cosa significa e da cosa dipende?
                                </h2>} />

                            <FadeInOverload offset={1550} JSX={
                               <p   className="scroll-fade-in-bottom">
                               L’immunità di gregge è la capacità di contrastare la circolazione e la trasmissione di un’infezione da parte di un gruppo di individui.
                                Può essere rappresentata dalla proporzione di soggetti che risultano essere immuni, per vaccinazione o per guarigione, sul totale della popolazione:
                                tale quantità è proporzionale alla contagiosità del virus.
                                 Infatti, più il virus è contagioso e più la percentuale di popolazione immune dovrà essere alta.
                                  L’immunità di gregge si può stimare con la seguente formula:
                               <BlockMath style={{fontWeight:"bold"}} className="equation">{"\\text{Immunità di gregge} = \\left( 1-\\frac{1}{R_0} \\right)*100"}</BlockMath>
                               L’indice 𝑅<sub>0</sub> rappresenta il tasso di contagiosità iniziale di un virus.
                                </p>} />
                            <FadeInOverload offset={1750} JSX={
                                <p className="scroll-fade-in-bottom">
                                Ogni variante del virus ha un suo specifico valore, <b>𝑅<sub>0</sub></b>, che può alzare o abbassare la soglia della popolazione che dovrà essere vaccinata.
    
                                Alla luce delle nuove varianti in circolazione, la soglia dell’immunità di gregge viene generalmente fatta partire dal <b>75%</b> della popolazione immune,
                                 in accordo con l'analisi di Anthony Fauci, immunologo statunitense di fama mondiale e massimo esperto di malattie infettive. In&#160;
                                 <a
                                    target="_blank"
                                    rel="noreferrer"
                                    style={linkStyle}
                                    className="aside-link"
                                    href="https://www.cnbc.com/2020/12/16/cnbc-transcript-dr-anthony-fauci-speaks-with-cnbcs-meg-tirrell-live-during-the-cnbc-healthy-returns-livestream-today.html"
                                    >questa recente intervista,
                                </a>
                                &#160; Fauci ha puntualizzato che tale soglia oscillerebbe tra il 75% e l’85%. 
    
                                Mantenendo un basso livello di contagiosità si riesce ad abbassare il numero di potenziali mutazioni del virus e, di conseguenza,
                                 la probabilità che appaiono nuove varianti più aggressive.
    
                            </p>} />
                            <FadeInOverload offset={1950} JSX={
                                <p className="scroll-fade-in-bottom">
                                L’immunità di gregge dipende poi dal numero di soggetti vaccinati. A questo proposito, vale la pena osservare più approfonditamente il 
                                <span style={{color:"#f5ba00", fontWeight:"bold"}}> processo di vaccinazione</span>.
                                 Questi fattori, infatti, possono determinarne l'avanzamento:
                                
                                <ul>
                                    <li><b>Il numero di vaccini disponibili;</b></li>
                                    <li><b>La capacità di vaccinare nelle singole aree geografiche: </b> esiste un limite strutturale definito dal <b>numero dei punti vaccinali</b> 
                                    &#160;sul territorio e dal <b>ritmo di vaccinazione</b> per ogni singolo punto vaccinale;</li>
                                    <li><b>Il numero di persone disposte a vaccinarsi: </b> la disinformazione, la divulgazione di informazioni contrastanti
                                     e talvolta tendenziose possono minare la fiducia della popolazione nei confronti dei vaccini e di conseguenza rallentare
                                      il raggiungimento dell’immunità di gregge;</li>
                                    <li><b>L'efficacia dei vaccini contro le varianti;</b></li>
                                    <li><b>L'efficacia dei vaccini nella trasmissione del virus: </b>sebbene le vaccinazioni mostrino un impatto sul calo delle ospedalizzazioni
                             e dei decessi, rimane da chiarire se e in che misura i vaccini riducano effettivamente la trasmissione.</li>
                                </ul>
        
                            </p>} />
                            <FadeInOverload offset={2150} JSX={
                                <p className="scroll-fade-in-bottom">
                                Inoltre, per poter parlare di immunità di gregge, è fondamentale tenere in considerazione <b>la durata della protezione vaccinale</b>:
                                 aspetto attualmente di difficile valutazione che ne rende complesso, se non impossibile, il raggiungimento. 
    
                                Ha senso, allora, continuare a parlare di <i >"immunità di gregge"</i>?  Come osservato fin qui, è certamente prematuro, e potrebbe rivelarsi persino utopico. 
                                Sarebbe dunque più adeguato parlare di "<span style={{color:"#f5ba00", fontWeight:"bold"}}>immunità generalizzata</span>", e di un conseguente New Normal. 
                                Nessuna normalità, però, potrà essere raggiunta con le strutture sanitarie affollate. Come evitare che accada?
                                Vaccinando la fascia di popolazione che, in caso di contagio, ha maggiore probabilità di ricovero: secondo uno &#160;
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    style={linkStyle}
                                    className="aside-link"
                                    href="https://www.cdc.gov/coronavirus/2019-ncov/covid-data/investigations-discovery/hospitalization-death-by-age.html"
                                    >studio effettuato dal Center for Disease Control and Prevention (CDC)
                                </a>,
                                  il rischio di ospedalizzazione aumenta esponenzialmente all’aumentare dell’età.
                                Dare la precedenza nel piano vaccinale a soggetti con età avanzata incide sensibilmente sulla diminuzione del sovraffollamento degli ospedali
                                 e garantisce le basi per una riapertura graduale delle attività economiche.
                            </p>} />
                        </div>
                    </aside>

                    <aside className="sidebar-landscape">
                        <div className="first-half show-x-small">
                            <FadeInOverload offset={1100} JSX={
                                <h2 className="font-grouch" style={{ fontSize: "24px" }}>
                                    Immunità di gregge: cosa significa e da cosa dipende?
                                </h2>} />

                            <FadeInOverload offset={1100} JSX={
                                <p   className="scroll-fade-in-bottom">
                                L’immunità di gregge è la capacità di contrastare la circolazione e la trasmissione di un’infezione da parte di un gruppo di individui.
                                 Può essere rappresentata dalla proporzione di soggetti che risultano essere immuni, per vaccinazione o per guarigione, sul totale della popolazione:
                                 tale quantità è proporzionale alla contagiosità del virus.
                                  Infatti, più il virus è contagioso e più la percentuale di popolazione immune dovrà essere alta.
                                   L’immunità di gregge si può stimare con la seguente formula:
                                <BlockMath style={{fontWeight:"bold"}} className="equation">{"\\text{Immunità di gregge} = \\left( 1-\\frac{1}{R_0} \\right)*100"}</BlockMath>
                                L’indice 𝑅<sub>0</sub> rappresenta il tasso di contagiosità iniziale di un virus.
                            </p>} />
                            <FadeInOverload offset={1300} JSX={
                                <p className="scroll-fade-in-bottom">
                                Ogni variante del virus ha un suo specifico valore, <b>𝑅<sub>0</sub></b>, che può alzare o abbassare la soglia della popolazione che dovrà essere vaccinata.
    
                                Alla luce delle nuove varianti in circolazione, la soglia dell’immunità di gregge viene generalmente fatta partire dal <b>75%</b> della popolazione immune,
                                 in accordo con l'analisi di Anthony Fauci, immunologo statunitense di fama mondiale e massimo esperto di malattie infettive. In&#160;
                                 <a
                                    target="_blank"
                                    rel="noreferrer"
                                    style={linkStyle}
                                    className="aside-link"
                                    href="https://www.cnbc.com/2020/12/16/cnbc-transcript-dr-anthony-fauci-speaks-with-cnbcs-meg-tirrell-live-during-the-cnbc-healthy-returns-livestream-today.html"
                                    >questa recente intervista,
                                </a>
                                &#160; Fauci ha puntualizzato che tale soglia oscillerebbe tra il 75% e l’85%. 
    
                                Mantenendo un basso livello di contagiosità si riesce ad abbassare il numero di potenziali mutazioni del virus e, di conseguenza,
                                 la probabilità che appaiono nuove varianti più aggressive.
    
                            </p>} />
                            <FadeInOverload offset={1400} JSX={
                                <p className="scroll-fade-in-bottom">
                                L’immunità di gregge dipende poi dal numero di soggetti vaccinati. A questo proposito, vale la pena osservare più approfonditamente il 
                                <span style={{color:"#f5ba00", fontWeight:"bold"}}> processo di vaccinazione</span>.
                                 Questi fattori, infatti, possono determinarne l'avanzamento:
                                
                                <ul>
                                    <li><b>Il numero di vaccini disponibili;</b></li>
                                    <li><b>La capacità di vaccinare nelle singole aree geografiche: </b> esiste un limite strutturale definito dal <b>numero dei punti vaccinali</b> 
                                    &#160;sul territorio e dal <b>ritmo di vaccinazione</b> per ogni singolo punto vaccinale;</li>
                                    <li><b>Il numero di persone disposte a vaccinarsi: </b> la disinformazione, la divulgazione di informazioni contrastanti
                                     e talvolta tendenziose possono minare la fiducia della popolazione nei confronti dei vaccini e di conseguenza rallentare
                                      il raggiungimento dell’immunità di gregge;</li>
                                    <li><b>L'efficacia dei vaccini contro le varianti;</b></li>
                                    <li><b>L'efficacia dei vaccini nella trasmissione del virus: </b>sebbene le vaccinazioni mostrino un impatto sul calo delle ospedalizzazioni
                             e dei decessi, rimane da chiarire se e in che misura i vaccini riducano effettivamente la trasmissione.</li>
                                </ul>
        
                            </p>} />
                            <FadeInOverload offset={1500} JSX={
                                <p className="scroll-fade-in-bottom">
                                Inoltre, per poter parlare di immunità di gregge, è fondamentale tenere in considerazione <b>la durata della protezione vaccinale</b>:
                                 aspetto attualmente di difficile valutazione che ne rende complesso, se non impossibile, il raggiungimento. 
    
                                Ha senso, allora, continuare a parlare di <i >"immunità di gregge"</i>?  Come osservato fin qui, è certamente prematuro, e potrebbe rivelarsi persino utopico. 
                                Sarebbe dunque più adeguato parlare di "<span style={{color:"#f5ba00", fontWeight:"bold"}}>immunità generalizzata</span>", e di un conseguente New Normal. 
                                Nessuna normalità, però, potrà essere raggiunta con le strutture sanitarie affollate. Come evitare che accada?
                                Vaccinando la fascia di popolazione che, in caso di contagio, ha maggiore probabilità di ricovero: secondo uno &#160;
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    style={linkStyle}
                                    className="aside-link"
                                    href="https://www.cdc.gov/coronavirus/2019-ncov/covid-data/investigations-discovery/hospitalization-death-by-age.html"
                                    >studio effettuato dal Center for Disease Control and Prevention (CDC)
                                </a>,
                                  il rischio di ospedalizzazione aumenta esponenzialmente all’aumentare dell’età.
                                Dare la precedenza nel piano vaccinale a soggetti con età avanzata incide sensibilmente sulla diminuzione del sovraffollamento degli ospedali
                                 e garantisce le basi per una riapertura graduale delle attività economiche.
                            </p>} />
                        </div>
                    </aside>

                    <main className="chart show-x-small" >
                    <div id="map" className="content" >
                        <div className="italy-map">
                            <img alt="" width="100%" id="image2" src={IG4} />
                        </div>
                    </div>
                </main>
                </div>
            </section>

    )
}
