import logo from "../img/logo_vettoriale.svg"

export const Footer = () => {
    return(
        <footer>
				<div className="nav-footer-component">
					<div className="menu-row">
						<a href="#Introduction" className="btn">
							<div>
								<div className="text font-grouch hide-x-small">Introduzione</div>
								<div className="text font-ubuntu show-x-small">1</div>
							</div>
						</a>
						<a href="#ImmunitaGregge" className="btn hide-x-small"
							><div>
								<div className="text font-grouch hide-x-small">Immunità di gregge</div>
							</div>
						</a>
						<a href="#ImmunitaGreggeMobile" className="btn show-x-small"
							><div>
								<div className="text font-ubuntu show-x-small">2</div>
							</div>
						</a>
						<a href="#ModelloEpidemiologico" className="btn">
						<div>
								<div className="text font-grouch hide-x-small">Modello Epidemiologico</div>
								<div className="text font-ubuntu show-x-small">3</div>
						</div>
						</a>
						<a href="#Simulator" className="btn hide-x-small"
							><div>
								<div className="text font-grouch hide-x-small">Simulatore</div>
							</div>
						</a>
						<a href="#Simulator-mobile" className="btn show-x-small"
							><div>
								<div className="text font-ubuntu show-x-small">4</div>
							</div>
						</a>
						<a href="#Insights" className="btn final-chapter"
							><div>
								<div className="text font-grouch hide-x-small">Insights</div>
								<div className="text font-ubuntu show-x-small">5</div>
							</div>
						</a>						
					</div>
					<a href="https://www.iconsulting.biz/highlight/" className="brand-strip">
						<div className="cta">Torna agli Highlights</div>
						<img src={logo} alt="Iconsulting website"/>
					</a>
				</div>
			</footer>
    )
}