export const Title = () => {

    return (
        <section id="section-1" className="section full-screen">
            <div>
                <h1 className="font-grouch color-yellow main-title">Independence Day Pandemic</h1>
                <h2 className="font-ubuntu color-white subtitle">
                    Contagi e vaccini: gli algoritmi svelano cosa ci aspetta (e cosa non dobbiamo aspettarci).
                </h2>
                <span className="scroll-hint color-white">
                    scrolla per continuare
                </span>
            </div>
        </section>
    )
}