import { Title } from "./TItle"
import { Introduction } from "./Introduction"
import { ModelloEpidemiologico } from "./ModelloEpidemiologico"
import { ImmunitaGreggeMobile } from "./ImmunitaGreggeMobile"
import { Insights } from "./Insights"
import { Simulator } from "./Simulator"
import { Highlights } from "./Highlights"
import { FirstDivider } from "./Divider"
import { Footer } from "./Footer"
import { ImmunitaGregge } from "./ImmunitaGregge"

export const SPA = () => {
	return (
		<body className="font-ubuntu">
			<div className="main">
				<Title />
				<Introduction />
				<FirstDivider />
				<ImmunitaGregge />
				<ImmunitaGreggeMobile />
				<ModelloEpidemiologico />
				<Simulator />
				<Insights />
				<div className="spacer"></div>
				<Highlights />
				
			</div>
			<Footer />
		</body>
	)
}