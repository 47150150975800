import { PowerBIEmbed } from "powerbi-client-react";
import { models } from 'powerbi-client';
import { Controller, Scene } from "react-scrollmagic";

export const Simulator = () => {
    const powerBiUrl = "https://app.powerbi.com/view?r=eyJrIjoiMmRjZjdlYWItYjJlZC00MmMzLTk0NzEtZTZlMTc4MDk2ODk3IiwidCI6IjE2NjhiMmQ5LTlkNDYtNDczOC05ZDI0LWVlYTkyYWU3NDVjOCIsImMiOjh9"
    return(
        <Controller>
            <Scene duration={300}
            triggerHook={"onLeave"}
            pin>
        <div>
        <section id="Simulator" className="section full-screen hide-x-small" style={{height: "53vw"}}>
        <div className="section-layout-container-report">
            <section className="interactive" style={{margin:"auto"}}>
                <main className="chart">
                        <PowerBIEmbed
                            cssClassName={"powerbi"}
                                
                            embedConfig={{
                                settings: {
                                    background: models.BackgroundType.Transparent,
                                    panes: {
                                        filters: {
                                            expanded: false,
                                            visible: false
                                        }
                                    },
                                    displayOption: models.DisplayOption.FitToPage,
                                },
                            type: "report", // Supported types: report, dashboard, tile, visual and qna
                            embedUrl: powerBiUrl
                            }}
                        />
                </main>
            </section>
        </div>
    </section>
    <section id="Simulator-mobile" className="section full-screen show-x-small" style={{height: "56vw"}}>
        <div className="section-layout-container full-screen">
            <div id="overlay-portrait" className="overlay-portrait">
                <div className="scroll-hint-portrait color-white">Ruota il dispositivo per visualizzare il simulatore</div>   
            </div>
            <section className="interactive">
                <main className="chart">
                    <PowerBIEmbed
                        cssClassName={"powerbi-mobile"}
                        embedConfig={{
                            settings: {
                                background: models.BackgroundType.Transparent,
                                panes: {
                                    filters: {
                                        expanded: false,
                                        visible: false
                                    }
                                },
                                displayOption: models.DisplayOption.FitToPage,
                            },
                        type: "report", // Supported types: report, dashboard, tile, visual and qna
                        embedUrl: powerBiUrl
                        }} />
                </main>
            </section>
        </div>
    </section>
    </div>
    </Scene>
    </Controller>
    )
}