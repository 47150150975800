// @flow
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import { FadeOutOverload } from './AnimationComponents';

import IG1 from "../img/IG-step1.png"
import IG2 from "../img/IG-step2.png"
import IG4 from "../img/IG-step4.png"


function useDimension() {
  const ref = useRef()

  const [dimensions, setDimensions] = React.useState({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0
  })

  const setDim = () => {
    if (!ref.current) return
    const rect = ref.current.getBoundingClientRect()

    setDimensions({
      bottom: Math.round(rect.bottom),
      height: Math.round(rect.height),
      left: Math.round(rect.left),
      right: Math.round(rect.right),
      top: Math.round(rect.top),
      width: Math.round(rect.width),
      x: Math.round(rect.x),
      y: Math.round(rect.y)
    })
  }


  React.useEffect(() => {
    const handleResize = debounce(setDim)
    window.addEventListener('resize', handleResize)
    return _ => { window.removeEventListener('resize', handleResize) }
  })

  // initial set
  useEffect(setDim, [])

  return [ref, dimensions]
}


function debounce(fn, ms = 200) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}


const ImmunitaStyle = styled.div`
.some-page-wrapper {
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 3;
}
.column2 {
  flex: 4;
	display: flex;
	padding: 0px 20px 0px 0px;
	flex-direction: column;
	background-color: #131C2E;
	color: #fff;
	font-size: 16px;
  display: flex;
}
@media screen and (min-width: 2000px) {
	.column2{
		flex: 4;
    display: flex;
    padding: 50px 50px 50px 50px;
    flex-direction: column;
    background-color: #131C2E;
    color: #fff;
    font-size: 16px;
    display: flex;
	}
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 10;
    height: 40vw!important;

  }

  .white {
    height: 40vw!important;
  }
  .yellow {
    height: 40vw!important;

  }
  .red {
    height: 40vw!important;

  }
  .orange {
    height: 40vw!important;

  }
  .green {
    height: 40vw!important;

  }
}


.green-column {
}
.nonvisible {
  opacity: 0;
  y: -10
  transition: 1s ease-in-out;
}
.visible {
  transition: 1s ease-in-out;
  y: 0;
  opacity: 1;
}

.white {
  background: url("") no-repeat;
  background-size: 100% 100%;
  transition: 1s ease-in-out;

}
.yellow {
  background: url(${IG1}) no-repeat;
  background-size: 100% 100%;
}
.red {
  background: url(${IG2}) no-repeat;
  background-size: 100% 100%;
}

.orange {
  background: url(${IG4}) no-repeat;
  background-size: 100% 100%;
}

.green {
  background: url(${IG4}) no-repeat;
  background-size: 100% 100%;
}

.section{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100vw;
  scroll-snap-align: start
}
`;

const Trigger = ({ children, onEnter, onLeave, ...props }) => {

  return <Scene {...props}>
    {(p, event) => {
      if (event.type === 'enter' && onEnter) {
        onEnter(event.scrollDirection) // FORWARD | REVERSE
      }

      if (event.type === 'leave' && onLeave) {
        onLeave(event.scrollDirection) // FORWARD | REVERSE
      }

      return children
    }}
  </Scene>

}

export const ImmunitaGregge = () => {

  const [pinRef, { height: pinnedHeight }] = useDimension()
  const [containerRef, { height: containerHeight }] = useDimension()
  const p1 = useRef("p1")
  const p2 = useRef("p2")
  const p3 = useRef("p3")

    const linkStyle={
    color: "white", 
    textDecoration: "underline",
    textDecorationColor: "#f5ba00",
    fontSize: "16px"
}
  const duration = containerHeight - pinnedHeight

  return <ImmunitaStyle> <div className='some-page-wrapper hide-x-small'>
    <Controller>
      <div className='row'>
      <section id="ImmunitaGregge" className="section hide-x-small" >
      <FadeOutOverload offset={1500} JSX={
               <div id="overlay-1" className="overlay hide-x-small" style={{height:"100vh", width:"100%"}}>
               <div className="scroll-hint hide-x-small color-white" style={{marginBottom: "auto", marginTop:"10vw"}}><div>scrolla per continuare</div></div>
           </div>} />
        <div className='column'>
          <div ref={pinRef} id='fooo' className='blue-column' style={{height:"30vw"}}>
          </div>
        </div>
        <aside className="sidebar">
        <div className="first-half overflow-y-scroll hide-x-small" >
        <div className='column2'>
          <Scene  pin={"#fooo"} duration={duration > 0 ? duration : 10} triggerHook="onLeave">
            <div ref={containerRef} className='green-column'>
              <div ref={p1} className="nonvisible">
              <h2 id="p1"  className="font-grouch" >
                  Immunità di gregge: cosa significa e da cosa dipende?
              </h2>
              <Trigger 
                onEnter={() => {pinRef.current.className = 'column yellow'; p1.current.className ="visible"}  }
                onLeave={() => {pinRef.current.className = 'column white'; p1.current.className ="nonvisible"}}
              >
                <p   className="scroll-fade-in-bottom">
                            L’immunità di gregge è la capacità di contrastare la circolazione e la trasmissione di un’infezione da parte di un gruppo di individui.
                             Può essere rappresentata dalla proporzione di soggetti che risultano essere immuni, per vaccinazione o per guarigione, sul totale della popolazione:
                             tale quantità è proporzionale alla contagiosità del virus.
                              Infatti, più il virus è contagioso e più la percentuale di popolazione immune dovrà essere alta.
                               L’immunità di gregge si può stimare con la seguente formula:
                            <BlockMath style={{fontWeight:"bold"}} className="equation">{"\\text{Immunità di gregge} = \\left( 1-\\frac{1}{R_0} \\right)*100"}</BlockMath>
                            L’indice 𝑅<sub>0</sub> rappresenta il tasso di contagiosità iniziale di un virus.
                        </p>
              </Trigger>
              </div>
              <div ref={p2} className="nonvisible">
              <Trigger 
                onEnter={() => {pinRef.current.className = 'column red'; p2.current.className ="visible"}}
                onLeave={() => {pinRef.current.className = 'column yellow'; p2.current.className ="nonvisible"}}
              >
                <p className="scroll-fade-in-bottom">
                            Ogni variante del virus ha un suo specifico valore, <b>𝑅<sub>0</sub></b>, che può alzare o abbassare la soglia della popolazione che dovrà essere vaccinata.

                            Alla luce delle nuove varianti in circolazione, la soglia dell’immunità di gregge viene generalmente fatta partire dal <b>75%</b> della popolazione immune,
                             in accordo con l'analisi di Anthony Fauci, immunologo statunitense di fama mondiale e massimo esperto di malattie infettive. In&#160;
                             <a
                                target="_blank"
                                rel="noreferrer"
                                style={linkStyle}
                                className="aside-link"
                                href="https://www.cnbc.com/2020/12/16/cnbc-transcript-dr-anthony-fauci-speaks-with-cnbcs-meg-tirrell-live-during-the-cnbc-healthy-returns-livestream-today.html"
                                >questa recente intervista,
                            </a>
                            &#160; Fauci ha puntualizzato che tale soglia oscillerebbe tra il 75% e l’85%. 

                            Mantenendo un basso livello di contagiosità si riesce ad abbassare il numero di potenziali mutazioni del virus e, di conseguenza,
                             la probabilità che appaiono nuove varianti più aggressive.

                        </p>
              </Trigger>
              </div>
              <div ref={p3} className="nonvisible">
              <Trigger 
                onEnter={() =>{ pinRef.current.className = 'column orange'; p3.current.className ="visible"}}
                onLeave={() =>{ pinRef.current.className = 'column red'; p3.current.className ="nonvisible"}}
              >
                <p className="scroll-fade-in-bottom">
                        L’immunità di gregge dipende poi dal numero di soggetti vaccinati. A questo proposito, vale la pena osservare più approfonditamente il 
                        <span style={{color:"#f5ba00", fontWeight:"bold"}}> processo di vaccinazione</span>.
                         Questi fattori, infatti, possono determinarne l'avanzamento:
                        
                        <ul>
                            <li><b>Il numero di vaccini disponibili;</b></li>
                            <li><b>La capacità di vaccinare nelle singole aree geografiche: </b> esiste un limite strutturale definito dal <b>numero dei punti vaccinali</b> 
                            &#160;sul territorio e dal <b>ritmo di vaccinazione</b> per ogni singolo punto vaccinale;</li>
                            <li><b>Il numero di persone disposte a vaccinarsi: </b> la disinformazione, la divulgazione di informazioni contrastanti
                             e talvolta tendenziose possono minare la fiducia della popolazione nei confronti dei vaccini e di conseguenza rallentare
                              il raggiungimento dell’immunità di gregge;</li>
                            <li><b>L'efficacia dei vaccini contro le varianti;</b></li>
                            <li><b>L'efficacia dei vaccini nella trasmissione del virus: </b>sebbene le vaccinazioni mostrino un impatto sul calo delle ospedalizzazioni
                             e dei decessi, rimane da chiarire se e in che misura i vaccini riducano effettivamente la trasmissione.</li>
                        </ul>

                    </p>
              </Trigger>
              <Trigger
                onEnter={() => {pinRef.current.className = 'column green'}}
                onLeave={() => {pinRef.current.className = 'column orange'}}
              >
                <p className="scroll-fade-in-bottom">
                            Inoltre, per poter parlare di immunità di gregge, è fondamentale tenere in considerazione <b>la durata della protezione vaccinale</b>:
                             aspetto attualmente di difficile valutazione che ne rende complesso, se non impossibile, il raggiungimento. 

                            Ha senso, allora, continuare a parlare di <i >"immunità di gregge"</i>?  Come osservato fin qui, è certamente prematuro, e potrebbe rivelarsi persino utopico. 
                            Sarebbe dunque più adeguato parlare di "<span style={{color:"#f5ba00", fontWeight:"bold"}}>immunità generalizzata</span>", e di un conseguente New Normal. 
                            Nessuna normalità, però, potrà essere raggiunta con le strutture sanitarie affollate. Come evitare che accada?
                            Vaccinando la fascia di popolazione che, in caso di contagio, ha maggiore probabilità di ricovero: secondo uno &#160;
                            <a
                                target="_blank"
                                rel="noreferrer"
                                style={linkStyle}
                                className="aside-link"
                                href="https://www.cdc.gov/coronavirus/2019-ncov/covid-data/investigations-discovery/hospitalization-death-by-age.html"
                                >studio effettuato dal Center for Disease Control and Prevention (CDC)
                            </a>,
                              il rischio di ospedalizzazione aumenta esponenzialmente all’aumentare dell’età.
                            Dare la precedenza nel piano vaccinale a soggetti con età avanzata incide sensibilmente sulla diminuzione del sovraffollamento degli ospedali
                             e garantisce le basi per una riapertura graduale delle attività economiche.
                        </p>
              </Trigger>
              </div>
            </div>
          </Scene>
          </div>
        </div>
        </aside>
        </section>
      </div>
    </Controller>
  </div>
  </ImmunitaStyle>
}
