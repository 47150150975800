import { Tween } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";

export const FadeInOverload = ({ JSX, offset }) => {
    return (
        <Controller>
            <Scene duration={40} triggerElement="#section-layout-container" offset={offset}>
                {(progress) => (
                    <Tween
                        from={{ css: { opacity: '0', y:10 }, ease: 'Circ.easeOutExpo' }}
                        to={{ css: { opacity: '1', y:0 }, ease: 'Circ.easeOutExpo' }}
                        totalProgress={progress}
                        paused
                    >
                        {JSX}
                    </Tween>
                )}
            </Scene>
        </Controller>
    );
};
export const FadeOutOverload = ({ JSX, offset }) => {
    return (
        <Controller>
            <Scene duration={100} triggerElement="#section-layout-container" offset={offset}>
                {(progress) => (
                    <Tween
                        from={{ css: { opacity: '1' }, ease: 'Circ.easeOutExpo' }}
                        to={{ css: { opacity: '0', display:"none", zIndex:-1 }, ease: 'Circ.easeOutExpo' }}
                        totalProgress={progress}
                        paused
                    >
                        {JSX}
                    </Tween>
                )}
            </Scene>
        </Controller>
    );
};