export const FirstDivider = () => {
    return (
        <>
        <div className="section-spacer" style={{ height: "150px", display:"flex", flexDirection:"column" }}>
            <h3 className="font-grouch color-yellow scroll-fade-in-left">
                Distanti ma connessi,<br></br><br />
				</h3>
            <h3 className="font-grouch color-yellow scroll-fade-in-left">
					together we spark!
				</h3>
        </div>
        <div className="beige-section" style={{height:".5vw"}}></div>   
        </>         
    )
}
