export const LineDivider = () => {
    return (
        <div className="line-container">
            <div className="line">
                <svg version="1.1" viewBox="0 0 3 191" style={{ width: "10px", height: "150px" }}>
                    <path
                        pid="0"
                        d="M1.976.5L2 200.25"
                        stroke="#F5BA00"
                        strokeWidth="2"
                        fill="none"
                        fillRule="evenodd"
                        strokeDasharray="15,20"
                    ></path>
                </svg>
            </div>
            <div className="circle">
                <svg version="1.1" viewBox="0 0 52 52" style={{ width: "50px", height: "50px" }}>
                    <circle pid="0" cx="26" cy="26" r="14" fill="#F5BA00" fillRule="evenodd"></circle>
                </svg>
                <svg version="1.1" viewBox="0 0 52 52" style={{ width: "50px", height: "50px" }}>
                    <circle
                        pid="0"
                        cx="26"
                        cy="26"
                        r="25"
                        stroke="#F5BA00"
                        strokeWidth="2"
                        fill="none"
                        fillRule="evenodd"
                    ></circle>
                </svg>
            </div>
        </div>
    );
};
