import chartSir from "../img/chart-SIR.png"
import chartSirMobile from "../img/chart-SIR-mobile.png"
import 'katex/dist/katex.min.css';
import { LineDivider } from "./LineDivider";
const mystyle=
{
    
    background: 'linear-gradient(to bottom, #f5ba0000, #f5ba0000, #f5ba00a6, #f5ba00a6)',
    "&:hover":{
        background: 'linear-gradient(to bottom, #ffba0000, #ffba00a6)',
        backgroundPosition:"10px 8px",
        textDecoration: "none",
        color: "#000",
    }
  }

export const ModelloEpidemiologico = () => {
    return(
        <section id="ModelloEpidemiologico" className="section">
        <div className="section-layout-container">
            <div className="section-title-container">
                <LineDivider/>  
                <h2 className="section-title font-grouch" style={{margintTop: "150px"}}>
                    Happy to see you again, SIR
                </h2>
            </div>
            <div className="section-content-container">
                <div className="content">
                    <p>
                    Per simulare l’andamento del piano vaccinale siamo partiti dal <b>modello epidemiologico compartimentale SIR</b>,
                     che divide la popolazione in insiemi distinti o compartimenti. Si tratta dello stesso modello utilizzato per il nostro &#160;
                        <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://epidemic-simulator.iconsulting.biz/"
                                className="highlight"
                                style={mystyle}
                                >DataTell. Epidemic Simulator
                        </a>, con il quale abbiamo esplorato il dilagare dei contagi all’inizio della pandemia.
                    </p>
                    <p>
                        Il <b>simulatore</b> si basa sull’estensione di un modello <b>SIR</b> &#160;
                        <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://arxiv.org/pdf/2005.08724.pdf"
                                className="highlight"
                                style={mystyle}
                                >(Palladino et al., 2020; 
                        </a>
                        <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://github.com/apalladi/covid_vaccine_model/tree/475bc3600d6e354611d391822659d63508cf5116"
                                className="highlight"
                                style={mystyle}
                                > &#160; Palladino, 2021) 
                        </a>
                        &#160; che tiene conto dell’immunità acquisita dagli individui tramite le vaccinazioni e delle restrizioni del periodo invernale.
                    </p>
                    <div className="hide-small" style={{position: "absolute", right:"2%", display:"flex", flexDirection:"column"}}>

                    <div className="hide-small" >
                            <div>
                                <a
                                    href="https://epidemic-simulator.iconsulting.biz/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="link">
                                        <div className="note-background">
                                            <p className="note-title">Simulatore Iconsulting</p>
                                            <p className="note-source">Datatell Iconsulting 2020.</p>
                                        </div>
                                </a>
                            </div>
                    </div>
                    <div className="hide-small" >
                            <div>
                                <a
                                    href="https://arxiv.org/pdf/2005.08724.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="link">
                                        <div className="note-background">
                                            <p className="note-title">Modelling the spread of Covid19 in Italy using a revised version of the SIR model</p>
                                            <p className="note-source">Fonte: Palladino et al.</p>
                                        </div>
                                </a>
                            </div>
                    </div>
                    <div className="hide-small" >
                            <div>
                                <a
                                    href="https://github.com/apalladi/covid_vaccine_model/tree/475bc3600d6e354611d391822659d63508cf5116"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="link">
                                        <div className="note-background">
                                            <p className="note-title">SIR 2.0 (2021)</p>
                                            <p className="note-source">Fonte: Palladino</p>
                                        </div>
                                </a>
                            </div>
                    </div>

                    </div>
                    </div>
                    <img alt="" id="chart-SIR" className="responsive-img" src={chartSir} />
                    <img alt="" id="chart-SIR-mobile" className="responsive-img" src={chartSirMobile}  style={{width:"85%"}}/>
                    </div>

                    <div className="section-content-container">
                    <div className="content">
                    <p>
                    Questo modello permette di simulare e quindi comprendere come certi scenari (es. l’applicazione di misure restrittive, la somministrazione di vaccini, …) 
                    possono influenzare l’esito di un'epidemia e valutarne l’impatto.
                    </p>
                    <p>
                    Abbiamo calibrato il modello separatamente per ogni regione italiana e per l’Italia, indicativamente da <b>fine Febbraio 2021 ad Aprile 2021</b>,
                     utilizzando i dati sull’ <a
                        target="_blank"
                        rel="noreferrer"
                        style={mystyle}
                        href="https://github.com/pcm-dpc/COVID-19"
                        className="highlight"
                        >epidemia 
                    </a>
                    &#160;e sulle <a
                        target="_blank"
                        rel="noreferrer"
                        style={mystyle}
                        href="https://github.com/italia/covid19-opendata-vaccini"
                        className="highlight"
                        >vaccinazioni</a>
                        &#160;estratti il <b>6 Giugno 2021</b> dagli archivi ufficiali del Ministero della Salute.
                      Nei mesi considerati, come sappiamo, la maggior parte delle regioni ha sperimentato chiusure e misure di contenimento a seguito della «terza ondata». 
                    </p>
                    <p>
                        Con i modelli così calibrati, abbiamo simulato il <b>decorso dell’epidemia fino a Settembre 2022</b>: sebbene il lasso temporale sia esteso,
                         il simulatore ci permette di illustrare l’impatto delle variazioni nella velocità di somministrazione delle vaccinazioni e nella contagiosità
                          del virus sugli sviluppi futuri dell’epidemia.  
                    </p>
                    <p><strong>Quali sono le condizioni che rendono affidabile il simulatore? </strong></p>
                    <div className="hide-small" style={{position: "absolute", right:"2%", display:"flex", flexDirection:"column"}}>

                    <div className="hide-small" >
                            <div>
                                <a
                                    href="https://github.com/pcm-dpc/COVID-19"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="link">
                                        <div className="note-background-double">
                                            <p className="note-title">Dati Epidemia</p>
                                            <p className="note-source">Dipartimento della Protezione Civile</p>
                                        </div>
                                </a>
                            </div>
                    </div>
                    <div className="hide-small" >
                            <div>
                                <a
                                    href="https://github.com/italia/covid19-opendata-vaccini"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="link">
                                        <div className="note-background-double">
                                            <p className="note-title">Dati Vaccinazioni</p>
                                            <p className="note-source">Governo Italiano</p>
                                        </div>
                                </a>
                            </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div className="section-content-container">
                    <div className="content">
                    <ul>
                        <li>
                            Il saldo della <b>popolazione</b>, ovvero la differenza tra i nati e i morti, è stabile e corrisponde alle stime della popolazione al 1° Gennaio 2021 <a
                                target="_blank"
                                rel="noreferrer"
                                style={mystyle}
                                href="http://demo.istat.it/"
                                className="highlight"
                                >(fonte Istat)
                            </a>, senza considerare fenomeni di migrazione e fenomeni demografici (nascite e decessi);
                        </li>
                        <li>
                            <b>Non vi è alcun periodo di incubazione</b>: non appena un soggetto viene infettato, può infettare altre persone;
                        </li>
                        <li>
                            La <b>popolazione è omogenea ed equamente distribuita nello spazio</b>,
                             al di là della differenza di distribuzione tra i grandi centri abitati e i piccoli paesi;
                        </li>
                        <li>
                        <div className="hide-small" style={{position: "absolute", right:"2%", display:"flex", flexDirection:"column"}}>

                        <div className="hide-small" >
                                <div>
                                    <a
                                        href="https://www.nytimes.com/2021/04/29/well/live/skipping-second-dose-coronavirus-vaccine.html"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="link">
                                            <div className="note-background-double">
                                                <p className="note-title">Why You Shouldn’t Skip Your Second Covid Shot</p>
                                                <p className="note-source">NY Times</p>
                                            </div>
                                    </a>
                                </div>
                        </div>
                        <div className="hide-small" >
                                <div>
                                    <a
                                        href="https://tg24.sky.it/mondo/2021/05/29/covid-regno-unito-contagi-variante"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="link">
                                            <div className="note-background-double">
                                                <p className="note-title">COVID, perché salgono i casi in UK</p>
                                                <p className="note-source">Sky TG 24</p>
                                            </div>
                                    </a>
                                </div>
                        </div>
                        </div>
                        Il simulatore prende in considerazione il <b>ciclo completo di vaccinazione</b>.
                          Come <a
                                target="_blank"
                                rel="noreferrer"
                                style={mystyle}
                                href="https://www.nytimes.com/2021/04/29/well/live/skipping-second-dose-coronavirus-vaccine.html"
                                className="highlight"
                                >evidenziato
                            </a> da <b>Paul Offit</b>, professore all'Università della Pennsylvania e
                           membro del comitato consultivo sui vaccini della <i>Food and Drug Administration</i>, la seconda dose di vaccino,
                            dove previste le due somministrazioni, è cruciale per creare un'immunità completa e prevenire le varianti.
                             Teoria che trova riscontro con quanto accaduto in&#160;
                             <a
                                target="_blank"
                                rel="noreferrer"
                                style={mystyle}
                                href="https://tg24.sky.it/mondo/2021/05/29/covid-regno-unito-contagi-variante"
                                className="highlight"
                                >UK
                            </a> 
                            &#160;nei primi giorni di Giugno: l’aumento dei contagi causato dalla B.1.617.2,
                              una mutazione "discendente" dalla variante indiana, ha colpito maggiormente  i vaccinati con la prima dose, oltre ai non vaccinati 
                        </li>
                        <li>
                        Una volta che gli individui hanno completato il ciclo previsto di vaccinazioni (monodose o prima e seconda dose dove previsto), <b>hanno piena immunità</b> (il vaccino somministrato è efficace al 100%)
                        </li>
                    </ul>
                    <p>
                        Nel <b><u>simulatore</u></b> che segue, mostriamo in che modo fattori come la contagiosità del virus e il ritmo di vaccinazione influiscano sul 
                        raggiungimento della cosiddetta «immunità di gregge » e sul «New Normal» a livello sia nazionale sia regionale.
                    </p>
                </div>
                    <div className="hide-small" style={{position: "absolute", right:"2%", display:"flex", flexDirection:"column"}}>
                    <div className="hide-small" >
                            <div>
                                <a
                                    href="http://demo.istat.it/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="link">
                                        <div className="note-background">
                                            <p className="note-title">Anagrafica 2021</p>
                                            <p className="note-source">Dati Istat</p>
                                        </div>
                                </a>
                            </div>
                    </div>
                    </div>
            </div>
        </div>
    </section>
    )
}