import warning from "../img/00_warning.png"
import orologio from "../img/01_orologio.png"
import virus from "../img/02_virus.png"
import rotelle from "../img/03_rotelle.png"
import lampadina from "../img/04_lampadina.png"
import health from "../img/05_health.png"

export const Insights = () => {
    const titles=[
        "Immunità di gregge...o forse no?",
        "Se aumentassero le vaccinazioni",
        "E se arrivassero nuove varianti?",
        "Due è meglio di uno",
        "Parliamo di immunità generalizzata",
        "Raggiungimento del New Normal?"
      ]
    
      const paragraphs = [
        <p>L’immunità di gregge dipende dal numero di soggetti vaccinati e, di conseguenza, i numerosi fattori che possono rallentare il processo di vaccinazione (es. scarsità di dosi, rifiuto alla somministrazione, efficacia contro le varianti e la trasmissione del virus, ...) possono renderne <b>difficile il raggiungimento</b>. Senza tener conto dell'incertezza riguardo la durata di protezione immunitaria, ancora di difficile comprensione. Con l’attuale contagiosità e un ritmo di vaccinazioni incrementale, ovvero 500.000 dosi fino ad Agosto 2021 e 750.000 da settembre 2021 a settembre 2022, potremmo prevedere un'immunità di gregge a livello nazionale intorno a <b>Febbraio 2022</b>.</p>,
        <p>Se venissero somministrate 750.000 dosi al giorno dal 7 Giugno 2021 l’immunità di gregge verrebbe anticipata a livello nazionale a <b>Gennaio 2022</b> e la maggior parte delle regioni vedrebbe tale obiettivo tra fine anno 2021 e inizio anno 2022.</p>,
        <p>Un aumento della contagiosità del virus, dovuto alla circolazione di varianti più aggressive, produrrebbe un innalzamento del numero necessario di immuni, allontanando ulteriormente il raggiungimento dell'immunità di gregge.</p>,
        <p>Le previsioni si basano sulle ipotesi del modello utilizzato e calcolano gli immuni come somma di guariti e <b>vaccinati a ciclo completo</b>: i cicli parziali, ovvero una sola dose per i vaccini che ne richiedono due, non forniscono una copertura completa. Il simulatore mostra il vantaggio di regioni come <b>Lombardia, Liguria e Basilicata</b> che, allo stato attuale, ha somministrato un gran numero di vaccinazioni a ciclo completo soprattutto nei mesi di aprile e maggio. Vengono invece penalizzate le regioni che hanno distribuito meno dosi o effettuato meno cicli completi. È il caso di <b>P.A. Trento</b>, che ha scelto di dare la priorità alla distribuzione delle prime dosi. Il simulatore considera un range temporale molto esteso, per cui le previsioni sono soggette a cambiamenti nel caso di variazioni nelle condizioni considerate o in fattori esterni (stagionali, epidemiologici…).</p>,
        <p>Allo stato attuale parlare di immunità di gregge è certamente prematuro. La prospettiva offerta dal simulatore è in linea con le indicazioni fornite dagli esperti: <b>l'obiettivo non è più l'immunità di gregge</b>, ma un’<b>immunità generalizzata</b> che, pur convivendo con il virus, ci permetterà di proteggere i soggetti più vulnerabili, riducendo le infezioni, i ricoveri e i decessi.</p>,
        <p>Se le somministrazioni venissero riservate prioritariamente agli Over 60, il New Normal a <b>livello nazionale</b> è previsto a <b>Settembre 2021</b>. La maggior parte delle <b>regioni</b> sperimenterà un New Normal tra Agosto ed Settembre.</p>
      ]

    return (
        <section id="Insights" className="section" style={{ display: "flex", flexDirection: "column" }}>
                <div className="infographic-title-div">
                    <h1 className="infographic-title">Independence Insights</h1>
                </div>
                <BeigeRectangle title={titles[0]} icon={warning} text={paragraphs[0]} />
                <BeigeRectangle title={titles[1]} icon={orologio} text={paragraphs[1]} />
                <BeigeRectangle title={titles[2]} icon={virus} text={paragraphs[2]} />
                <BeigeRectangle title={titles[3]} icon={rotelle} text={paragraphs[3]} />
                <BeigeRectangle title={titles[4]} icon={lampadina} text={paragraphs[4]} />
                <BeigeRectangle title={titles[5]} icon={health} text={paragraphs[5]} />
        </section>
    )
}
  
  const BeigeRectangle = ({text, icon, title}) => {
    return(
      <div className="beige-infographic">
        <div className="infographic-spacer"><p className="infographic-subtitle">{title}</p></div>
        <div className="beige-infographic-div">
        <div  className="beige-infographic-icon">
          <img width="100%" src={icon} alt=""/>
        </div>
        <p className="beige-infographic-text">{text}</p>
        </div>
      </div>
    )
  }