export const Highlights = () => {
    return (
        <section id="section-7" className="section">
            <div className="section-layout-container">
                <div className="section-content-container">
                    <div className="content">
                        <div className="text">Vuoi saperne di più?</div>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.iconsulting.biz/independence-day-pandemic-simulator/"
                            style={{ textDecoration: "none" }}>
                            <div className="button">
                                <div className="text-btn">Clicca qui</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}