import '../styles/main.css';
import '../styles/normalize.css';
import '../styles/slider.css';
import '../styles/style.css';
import { SPA } from "./SPA"

function App() {
  return (
        <SPA />
  );
}

export default App;
