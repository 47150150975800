import { LineDivider } from "./LineDivider"

export const Introduction = () => {
    return(
        <section id="Introduction" className="section">
				<div className="section-layout-container">
					<div className="section-title-container">
						<LineDivider />
						<h2 className="section-title font-grouch">
                            Independence Day Pandemic​
						</h2>
					</div>
					<div className="section-content-container">
						<div className="content">
							<p>
								Le domande che in questo periodo tutti ci siamo posti sono almeno due: quando finirà la pandemia da COVID-19? Quando torneremo alla normalità? 
							</p>
							<p>
								Come osserviamo quotidianamente, il dibattito su quando potremo considerare conclusa la pandemia è acceso
								 e i temi più discussi riguardano il raggiungimento dell'<b>«immunità di gregge»</b> e il <b>«New Normal»</b>.
							</p>
							<p>
								Se con <i>immunità di gregge</i> ci riferiamo alla capacità collettiva di resistere al contagio grazie all’immunizzazione della gran parte degli individui,
								con <i>New Normal</i> intendiamo il ripristino delle condizioni pre-pandemiche delle attività sociali ed economiche.
							</p>
							<p>
								A complicare la realizzazione di questi scenari, intervengono diversi fattori: dalla diffusione delle varianti alla propensione dei cittadini a sottoporsi al vaccino.
								 Con l'aiuto di alcuni <b>modelli epidemiologici</b>, possiamo tuttavia simulare l’impatto degli aspetti principali che potranno finalmente condurci al "giorno dell'Indipendenza" da COVID-19.
							</p>
						</div>
					</div>
				</div>
			</section>
    )
}

